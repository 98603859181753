<template src="./LoginAdmin.html"></template>


<script>

import {sha512} from "js-sha512";

export default {
  name: "login",

  data() {
    return {

      ready: false,
      one_theater: false,

      theaters:[],
      theater_select:0,

      // никто
      username: "",
      password: "",

      valid: false,
      show: false,

      valid_restore: false,
      valid_pwd: false,

      message_show: false,
      message: "Привет",

      link: "",
      isGoodLink: false,

      tab: null,

      nameRules: [
        v => !!v || 'Это обязательное поле',
        v => v.length <= 60 || 'Name must be less than 10 characters',
      ],

      theaterRules:[
        v => !!v || 'Это обязательное поле'
      ],

      pwd_rules: [
        value => !!value || 'Обязательное поле.',
        v => v.length >= 7 || 'Как минимум 7 символов',
      ],



    };
  },

  computed: {

    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
      !this.$v.name.required && errors.push('Это обязательное поле')
      return errors
    },

  },

  created() {

    this.$store.state.api.getPlayhouses()
      .then( data => {

        this.theaters = data;

        if (this.$route.params.id>0){
          //console.log(this.$route.params.id);
          this.theater_select = parseInt(this.$route.params.id);
          this.one_theater = true;
        }
        this.ready = true;

        //console.log(this.$route.params);

        // найдем ссылку на восстановление, если есть
        if ( this.$route.params.link){

          this.link = this.$route.params.link;
          //console.log(this.link);

          this.checkRestoreLink();
          // переключить вкладку, если ссылка правильная

        }

      })

  },

  methods: {
    login: function() {

      const isAdmin = true;

      const { username, password, theater_select  } = this;

      this.$store.dispatch("auth", { username, password, theater_select, isAdmin })
          .then(() => {
            console.log('in');
            //this.$store.dispatch("loadConfig");
      });
    },

    send_restore_link: function(){

      console.log("restore check");

      let post_data = {

        base:{
          type:"restore",
          act: "create"
        },

        user:{
          email:    this.username.trim(),
          theater:  this.theater_select,
          local:    true,
        }

      };

      //console.log(post_data);

       this.$store.state.api.admUser(post_data)
        .then( data =>{

          //console.log(data);

          if (data.b){
            this.message = data.m;
          }
          else{
            this.message =
                "Мы не нашли указанную почту: <br>" +
                "<b>" + this.email + "</b><br><br>" +
                "Попробуем другую?";
          }

          this.message_show = true;

        })

    },

    change_pwd: function(){

      //console.log('change pwd by link');

      this.message = "Проверяем данные";


      let post_data = {

        base:{
          type:"restore",
          act: "change_pwd"
        },

        user:{
          link:       this.link,
          pwd_hash:   sha512(this.password),
          theater:    this.theater_select
        }

      };

      //console.log(post_data);

      let pr2 = this.$store.state.api.admUser(post_data);

      pr2.then( data =>{

        //console.log(data);

        if (data.b){
          this.message = "Поменяли пароль. Можно входить.";
        }
        else{
          this.message =
              "Что-то не вышло с первого раза. Печально.";
        }

        this.message_show = true;

      })






    },

    resetPWD(){

      console.log('reset');
      this.tab = 1;


    },


    // проверить - рабочая ли ссылка по которой пришли
    checkRestoreLink(){

      let post_data = {

        base:{
          type:"restore",
          act: "visit"
        },

        user:{
          link:     this.link,
          theater:  this.theater_select,
        }

      };

      //console.log(post_data);

      let pr2 = this.$store.state.api.admUser(post_data);

      pr2.then( data =>{

        //console.log(data);

        if (data.b){

          // переключаемся на нужную вкладку
          if (data.isLocal){
            this.isGoodLink = true;
            this.tab = 2;
          }
        }
        else{
          this.message = "Ссылка для восстановления просрочена";
        }

      })

    },

  }


}
</script>

<style scoped>

</style>

